export const postMethod = async (url,data) =>{
    console.log(url,data)

    try{
        //fetch the api
        const response = await fetch(url,{
            method: 'POST',
            headers: {'Content-Type':'application/json'},
            body: data,
        });
        console.log(response)
        // api response handling
        if(response.ok){
            return await response.json();
        } else{
            throw new Error("Failed to fetch data !");
        }
    }catch(e){
        throw new Error(e.message);
    }
};