import React from 'react';
import { useState, useEffect } from 'react';
import './App.css';
import { api_SaveStudent } from './api/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  useEffect(() => {
    document.title = "Student Registration";
  }, []);

  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [email, setEmail] = useState("");


  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleRegister = async () => {
    try {
      if (!firstname || !contactNumber || !email) {
        toast.error("All fields are mandatory !!");
        return;
      }

      if (!isValidPhoneNumber(contactNumber)) {
        toast.error("Please enter a valid 10-digit phone number.");
        return;
      }

      if (!isValidEmail(email)) {
        toast.error("Please enter a valid email address.");
        return;
      }

      let data = await api_SaveStudent(firstname, lastname, contactNumber, email);

      if (data !== '') {
        toast.success("Registered Successfully !!");
      }
    } catch (e) {
      toast.error("Error: " + e.message);
    }
  };


  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber);
  };


  return (
    <div className="body-inner">

      <header id="header" className="header-always-fixed">
        <div className="header-inner">
          <div class="container">
            <div id="logo">
              <img src="https://course.daitonn.com/DTLogo.png" alt="Logo" class="logo-image" />
              <span class="logo-dark">DAITONN LEARN</span>
            </div>
          </div>
        </div>
      </header>


      <div className="background-signup">
        <div className="container-register">
          <h1>Register</h1>
          <br />

          <input
            type='text'
            placeholder='Enter Your Name'
            value={firstname}
            onChange={handleFirstNameChange} className="input-field" />
          <br />

          <input
            style={{ display: 'none' }}
            type='text'
            placeholder='Enter Your Last Name'
            value={lastname}
            onChange={handleLastNameChange} className="input-field" />

          <input
            type='tel'
            placeholder='Enter Your Contact'
            value={contactNumber}
            onChange={(e) => setContactNumber(e.target.value)}
            className="input-field" />
          <br />

          <input
            type='email'
            placeholder='Enter Your Email Address'
            value={email}
            onChange={(e) => setEmail(e.target.value)} className="input-field" />
          <br /><br /><br />
          <button onClick={handleRegister} className="button">Register</button>
          <br /><br />
        </div>
      </div>

      <footer id="footer">
        <div className="copyright-content">
          <div className="container">
            <div className="copyright-text text-center">&copy; 2024 DAITONN TECH -All Rights Reserved.</div>
          </div>
        </div>
      </footer>
      <ToastContainer />
    </div>
  );
}

export default App;
