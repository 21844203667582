import { postMethod } from "./postMethod";

const baseURL = 'http://localhost:53973/api/service'

export const api_SaveStudent = (
    FIRSTNAME,
    LASTNAME,
    CONTACTNUMBER,
    EMAIL
) => {
    let url = `${baseURL}/SaveStudent`;
    console.log(url);
    return postMethod(url, JSON.stringify({
        FirstName: FIRSTNAME,
        LastName: LASTNAME,
        ContactNumber: CONTACTNUMBER,
        Email: EMAIL        
    }));
};